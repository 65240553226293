<template>
  <div class="container-top">
    <div class="pc container">
      <div class="h5 main title">서비스 이용약관</div>
      <div v-html="policy.hosting" class="sun-editor-editable body2-medium"></div>
    </div>
    <div class="mobile container">
      <div class="h8 main title">서비스 이용약관</div>
      <div v-html="policy.hosting" class="sun-editor-editable body4"></div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "term",
    data() {
      return {
        policy: {
          hosting: ''
        }
      }
    },
    created () {
      this.$axios.get('public/service/policy').then(res => {
        if(res.status === 200) {
          this.policy = res.data.policy;
        }
      });
    },
    methods: {
    }
  }

</script>

<style lang="stylus" scoped>
  .title
    padding 60px 0 28px 0
    margin-bottom 0
  @media (max-width:1024px)
    .title
      padding 24px 0
</style>
